<template>
  <div class="AmountsSelect">
    <!-- Amounts -->
    <AmountSingleSelect
      v-for="(amount, index) in amounts"
      :key="index"
      :index="index"
      :amount="amount"
      :resourceType="resourceType"
      :resourceCategoryType="resourceCategoryType"
      :isValid="getValidStatus(amount)"
      :isActive="isActive"
      :unitsHelpingText="unitsHelpingText"
      :amountToCopy="amountToCopy"
      @update="onAmountUpdate" />

    <!-- Next button - used in edit type -->
    <div
      v-if="showButton"
      class="ButtonWrap">
      <Button
        :text="mixWB('NEXT')"
        :isDisabled="isButtonDisabled"
        @button-click="onButtonClick" />
    </div>

    <!-- Save button - used on amount page -->
    <div
      class="ButtonWrap"
      v-if="isAmountPage">
      <Button
        :text="mixWB('SAVE')"
        :isDisabled="isButtonDisabled"
        @button-click="onButtonClick" />
    </div>

  </div>
</template>

<script>
import EventBus from '@/EventBus'
import Type from '@/globals/javascript/models/Type'
import AmountSingleSelect from '@/components/ScreeningTypeSections/AmountSingleSelect.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import { union } from 'lodash-es'

export default {
  name: 'AmountsSelect',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    screeningType: {
      type: Object,
      required: true,
    },
    amountToCopy: {
      type: Object,
      required: false,
    },
    isAmountPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      amounts: [],
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'combinedListOfUnits',
    ]),
    resourceType() {
      return this.screeningType.getResourceType()
    },
    resourceCategoryType() {
      return this.screeningType.getResourceCategoryType()
    },
    isCustomType() {
      return !!this.screeningType.type.customTypeName
    },
    selectedUnitIDs() {
      return this.screeningType?.unitIDs || []
    },
    showButton() {
      if (!this.isActive || this.isAmountPage) {
        return false
      }

      return this.amounts.find((x) => !['', 'skip'].includes(x.data.type))
    },
    isButtonDisabled() {
      return !this.amounts.every((x) => this.getValidStatus(x))
    },
    areAllAmountsValid() {
      return this.amounts.every((x) => this.getValidStatus(x))
    },
    unitsHelpingText() {
      if (!this.selectedUnits) {
        return []
      }

      const buildingIDs = this.selectedUnitIDs.reduce((prev, unitID) => {
        const unit = this.selectedUnits.find((x) => x.id === unitID)

        if (unit.type === 'building') {
          prev = union(prev, [unit.id])
        }
        else {
          prev = union(prev, [unit.data.buildingID])
        }

        return prev
      }, [])

      return buildingIDs.reduce((prev, buildingID) => {
        const building = this.combinedListOfUnits.find((x) => x.id === buildingID)

        if (!building) {
          return prev
        }

        let buildingName = ''
        if (building?.bbrData?.Bygningsnr) {
          buildingName = `${ this.mixWB('BUILDING') } ${ building?.bbrData?.Bygningsnr }`
        }
        else if (building.manualData.title) {
          buildingName = building.manualData.title
        }

        let buildingAreaText = ''
        if (building?.bbrData?.BYG_BEBYG_ARL) {
          buildingAreaText = `${ this.mixWB('AREA') } ${ building.bbrData.BYG_BEBYG_ARL }`
        }
        else if (building.manualData.builtArea) {
          buildingAreaText = `${ this.mixWB('AREA') } ${ building.manualData.builtArea }`
        }

        if (!buildingName || !buildingAreaText) {
          return prev
        }

        const data = {
          id: buildingID,
          text: `${
            buildingName
          }: ${
            buildingAreaText
          } ${
            this.mixWB('M2')
          }`,
        }

        prev.push(data)

        return prev
      }, [])
    },
  },
  methods: {
    getShowStatus(index) {
      if (index === 0) {
        return true
      }

      return this.getValidStatus(this.amounts[index - 1])
    },
    getValidStatus(amount) {
      const { data } = amount
      if (data.type === '') {
        return false
      }

      if (data.type === 'skip' && !this.isAmountPage) {
        return true
      }

      if (data.type === 'm2') {
        if (
          data.m2 <= 0
          || data.thickness <= 0
          || (amount.isCustomType && data.customDensity <= 0)
        ) {
          return false
        }
      }

      if (data.type === 'm3') {
        if (data.m3 <= 0 || (amount.isCustomType && data.customDensity <= 0)) {
          return false
        }
      }

      if (data.type === 'meters') {
        if (
          data.meters <= 0
          || data.width <= 0
          || data.height <= 0
          || (amount.isCustomType && data.customDensity <= 0)
        ) {
          return false
        }
      }

      if (data.type === 'pcs') {
        if (data.pcs <= 0 || data.weight <= 0) {
          return false
        }
      }

      return true
    },
    onAmountUpdate({
      index,
      data,
      isOnLoad,
    }) {
      const amount = this.amounts[index]
      amount.data = data

      if (isOnLoad) {
        this.$emit('update-on-load', this.amounts)
        return
      }

      this.$emit('update')

      if (!this.showButton && this.areAllAmountsValid) {
        this.$emit('finished', this.amounts)
      }
    },
    onAmountsReset() {
      this.amounts = []

      if (this.resourceType) {
        Object.keys(this.resourceType.materialData).forEach((materialID) => {
          const amountData = Type.getCleanAmountEntry()
          amountData.materialID = materialID
          this.amounts.push(amountData)
        })
        return
      }

      // Custom type
      const amountData = Type.getCleanAmountEntry()
      amountData.isCustomType = true
      this.amounts.push(amountData)
    },
    onButtonClick() {
      if (this.isAmountPage) {
        this.$emit('save', this.amounts)
      }
      else {
        this.$emit('finished', this.amounts)
      }
    },
    onTypeLoad({ amounts }) {
      this.amounts = JSON.parse(JSON.stringify(amounts))
    },
  },
  components: {
    AmountSingleSelect,
    Button,
  },
  created() {
    this.onAmountsReset()
    EventBus.$on('amounts-reset', this.onAmountsReset)
    EventBus.$on('load-type-edit', this.onTypeLoad)
  },
  destroyed() {
    EventBus.$off('amounts-reset', this.onAmountsReset)
    EventBus.$off('load-type-edit', this.onTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .AmountsSelect
    padding-top 15px
    padding-bottom 10px

</style>
