<template>
  <div class="AmountSingleSelect">
    <SectionHeadline :text="getTitle()">
      <template v-slot:icon>
        <AmountIcon />
      </template>
    </SectionHeadline>

    <!-- Help text -->
    <div
      v-if="unitsHelpingText.length"
      class="BuildingInfoWrap">
      <span
        v-for="item in unitsHelpingText"
        :key="item.id">{{ item.text }}</span>
    </div>

    <!-- Options -->
    <div class="OptionList">
      <template v-for="option in options">
        <div
          v-if="checkToShowOption(option.id)"
          class="Option"
          :key="option.id"
          :class="{ IsSelected: option.id === amount.data.type }"
          @click="onOptionClick(option.id)">
          <span>{{ mixWB(option.translation) }}</span>
        </div>
      </template>
    </div>

    <!-- M2 -->
    <div
      class="AmountInputWrap"
      v-if="amount.data.type === 'm2'">
      <span class="Title">{{ mixWB('M2') }}</span>
      <div class="Row">
        <InputField
          name="m2"
          type="number"
          :unit="mixWB('M2')"
          :value="amount.data.m2"
          @on-update="onInputUpdate" />
        <span>{{ mixWB('WITH_THICKNESS') }}</span>
        <InputField
          name="thickness"
          type="number"
          :unit="mixWB('MILLIMETER_SHORT')"
          :value="amount.data.thickness"
          @on-update="onInputUpdate" />
      </div>
      <div v-if="amount.isCustomType">
        <span class="Title">{{ mixWB('DENSITY_PER_M3') }}</span>
        <InputField
          name="customDensity"
          type="number"
          :unit="mixWB('TONS')"
          :value="amount.data.customDensity"
          @on-update="onInputUpdate" />
      </div>
      <span class="Amount">
        {{ mixWB('ESTIMATED_AMOUNT') }}: {{ m2Amount ? mixWB('X_TONS', [m2Amount]) : '-' }}
      </span>
    </div>

    <!-- M3 -->
    <div
      class="AmountInputWrap"
      v-if="amount.data.type === 'm3'">
      <span class="Title">{{ mixWB('M3') }}</span>
      <div class="Row">
        <InputField
          name="m3"
          type="number"
          :unit="mixWB('M3')"
          :value="amount.data.m3"
          @on-update="onInputUpdate" />
      </div>
      <div v-if="amount.isCustomType">
        <span class="Title">{{ mixWB('DENSITY_PER_M3') }}</span>
        <InputField
          name="customDensity"
          type="number"
          :unit="mixWB('TONS')"
          :value="amount.data.customDensity"
          @on-update="onInputUpdate" />
      </div>
      <span class="Amount">
        {{ mixWB('ESTIMATED_AMOUNT') }}: {{ m3Amount ? mixWB('X_TONS', [m3Amount]) : '-' }}
      </span>
    </div>

    <!-- Meters -->
    <div
      class="AmountInputWrap"
      v-if="amount.data.type === 'meters'">
      <span class="Title">{{ mixWB('METERS') }}</span>
      <div class="Row">
        <span>{{ mixWB('LENGTH_SHORT') }}:</span>
        <InputField
          name="meters"
          type="number"
          :unit="mixWB('METERS')"
          :value="amount.data.meters"
          @on-update="onInputUpdate" />
      </div>
      <div class="Row">
        <span>{{ mixWB('WIDTH_SHORT') }}:</span>
        <InputField
          name="width"
          type="number"
          :unit="mixWB('MILLIMETER_SHORT')"
          :value="amount.data.width"
          @on-update="onInputUpdate" />
        <span>{{ mixWB('HEIGTH_SHORT') }}:</span>
        <InputField
          name="height"
          type="number"
          :unit="mixWB('MILLIMETER_SHORT')"
          :value="amount.data.height"
          @on-update="onInputUpdate" />
      </div>
      <div v-if="amount.isCustomType">
        <span class="Title">{{ mixWB('DENSITY_PER_M3') }}</span>
        <InputField
          name="customDensity"
          type="number"
          :unit="mixWB('TONS')"
          :value="amount.data.customDensity"
          @on-update="onInputUpdate" />
      </div>
      <span class="Amount">
        {{ mixWB('ESTIMATED_AMOUNT') }}: {{ metersAmount ? mixWB('X_TONS', [metersAmount]) : '-' }}
      </span>
    </div>

    <!-- Pieces -->
    <div
      class="AmountInputWrap"
      v-if="amount.data.type === 'pcs'">
      <span class="Title">{{ mixWB('PIECES') }}</span>
      <div class="Row">
        <InputField
          name="pcs"
          type="number"
          :unit="mixWB('PCS')"
          :value="amount.data.pcs"
          @on-update="onInputUpdate" />
        <span>{{ mixWB('WITH_WEIGHT') }}</span>
        <InputField
          name="weight"
          type="number"
          :unit="mixWB('TONS')"
          :value="amount.data.weight"
          @on-update="onInputUpdate" />
        <span>{{ mixWB('PER_PIECE') }}</span>
      </div>
      <span class="Amount">
        {{ mixWB('ESTIMATED_AMOUNT') }}: {{ pcsAmount ? mixWB('X_TONS', [pcsAmount]) : '-' }}
      </span>
    </div>
  </div>
</template>

<script>
import Type from '@/globals/javascript/models/Type'
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import InputField from '@/components/FormElements/InputField.vue'
import AmountIcon from '@/assets/svg/amount.svg?inline'

export default {
  name: 'AmountSingleSelect',
  props: {
    amount: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    resourceType: {
      type: [Object, Boolean],
      required: true,
    },
    resourceCategoryType: {
      type: [Object, Boolean],
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    unitsHelpingText: {
      type: Array,
      required: true,
    },
    amountToCopy: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: [
        {
          id: 'skip',
          translation: 'SKIP',
        },
        {
          id: 'm2',
          translation: 'M2',
        },
        {
          id: 'm3',
          translation: 'M3',
        },
        {
          id: 'meters',
          translation: 'METERS',
        },
        {
          id: 'pcs',
          translation: this.mixWB('PIECES'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'materialsAsObject',
      'currentScreeningData',
    ]),
    material() {
      return this.materialsAsObject[this.amount?.materialID]
    },
    categoryTypeMaterialData() {
      if (!this.resourceCategoryType || !this.amount.materialID) {
        return false
      }

      return this.resourceCategoryType.materialData[this.amount.materialID]
    },
    typeMaterialData() {
      if (!this.resourceType || !this.amount.materialID) {
        return false
      }

      return this.resourceType.materialData[this.amount.materialID]
    },
    m2Amount() {
      if (!this.isValid) {
        return 0
      }

      let density = 0
      if (this.amount.isCustomType) {
        density = this.amount.data.customDensity
      }
      else {
        density = this.material.metaData.density.value
      }

      return this.mixFormatNumber(this.amount.data.m2
       * (this.amount.data.thickness / 1000)
       * density, 3)
    },
    m3Amount() {
      if (!this.isValid) {
        return 0
      }

      let density = 0
      if (this.amount.isCustomType) {
        density = this.amount.data.customDensity
      }
      else {
        density = this.material.metaData.density.value
      }

      return this.mixFormatNumber(this.amount.data.m3 * density, 3)
    },
    metersAmount() {
      if (!this.isValid) {
        return 0
      }

      let density = 0
      if (this.amount.isCustomType) {
        density = this.amount.data.customDensity
      }
      else {
        density = this.material.metaData.density.value
      }

      return this.mixFormatNumber(this.amount.data.meters
        * (this.amount.data.width / 1000)
        * (this.amount.data.height / 1000)
        * density, 3)
    },
    pcsAmount() {
      if (!this.isValid) {
        return 0
      }
      return this.mixFormatNumber(this.amount.data.pcs
        * this.amount.data.weight, 3)
    },
  },
  watch: {
    isActive() {
      this.checkToSkipAmount()
    },
  },
  methods: {
    checkToShowOption(optionID) {
      // Options: M3 and skip
      if (['skip', 'm3'].includes(optionID)) {
        return true
      }

      // Options: m2, meters, psc
      // - Check that category type is set as off
      if (this.categoryTypeMaterialData?.units?.[optionID]?.status === 0) {
        return false
      }
      // - Check that category type is set as on
      if (this.categoryTypeMaterialData?.units?.[optionID]?.status === 2) {
        return true
      }
      // - Check that resource type is set as off
      const unit = this.typeMaterialData?.units?.[optionID]
      if (unit && unit.isActive === false) {
        return false
      }

      return true
    },
    checkToSkipAmount() {
      if (
        this.isActive
        && this.currentScreeningData.isSkipAmount
        && !this.amount.data.type
      ) {
        this.onOptionClick('skip')
      }
    },
    setDefaultValues(data) {
      if (data.type === 'm2') {
        // Check category type
        let unit = null
        if (this.categoryTypeMaterialData?.units?.[data.type]?.status === 2) {
          unit = this.categoryTypeMaterialData.units[data.type]
        }

        // Check type
        else {
          unit = this.typeMaterialData?.units?.[data.type]
        }

        data.thickness = unit?.fields?.thickness.value || 0

        // Check to copy
        if (this.amountToCopy) {
          data.m2 = this.amountToCopy.data.m2
        }
      }

      if (data.type === 'meters') {
        // Check category type
        let unit = null
        if (this.categoryTypeMaterialData?.units?.[data.type]?.status === 2) {
          unit = this.categoryTypeMaterialData.units[data.type]
        }

        // Check type
        else {
          unit = this.typeMaterialData?.units?.[data.type]
        }

        data.width = unit?.fields?.width.value || 0
        data.height = unit?.fields?.height.value || 0
      }

      if (data.type === 'pcs') {
        // Check category type
        let unit = null
        if (this.categoryTypeMaterialData?.units?.[data.type]?.status === 2) {
          unit = this.categoryTypeMaterialData.units[data.type]
        }

        // Check type
        else {
          unit = this.typeMaterialData?.units?.[data.type]
        }

        data.weight = unit?.fields?.weight?.value || 0
      }
    },
    getTitle() {
      if (
        this.amount.materialID
        && this.resourceType?.materialIDs?.length > 1
      ) {
        const material = this.materialsAsObject[this.amount.materialID]
        return `${ this.mixWB('ESTIMATED_AMOUNT') } - ${ this.mixWB(material.translation) }`
      }

      if (this.resourceCategoryType?.translation) {
        return `${
          this.mixWB('ESTIMATED_AMOUNT')
        } - ${
          this.mixWB(this.resourceCategoryType.translation)
        }`
      }

      if (this.resourceType?.translation) {
        return `${
          this.mixWB('ESTIMATED_AMOUNT')
        } - ${
          this.mixWB(this.resourceType.translation)
        }`
      }

      return this.mixWB('ESTIMATED_AMOUNT')
    },
    onOptionClick(optionID, isOnLoad = false) {
      if (this.amount.data.type === optionID) {
        return
      }

      const data = { ...Type.getCleanAmountData() }
      data.type = optionID

      // Set default values
      this.setDefaultValues(data)

      this.emitData(data, isOnLoad)
    },
    onInputUpdate({ name, value }) {
      const data = { ...this.amount.data }

      data[name] = value

      this.emitData(data)
    },
    emitData(data, isOnLoad = false) {
      this.$emit('update', { index: this.index, data, isOnLoad })
    },
    checkToAutosetAmountOnLoad() {
      if (!this.amountToCopy) {
        return
      }

      // m2
      if (this.checkToShowOption('m2') && this.amountToCopy.data.m2) {
        this.onOptionClick('m2', true)
      }
    },
  },
  components: {
    SectionHeadline,
    InputField,
    AmountIcon,
  },
  created() {
    this.checkToAutosetAmountOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .AmountSingleSelect
    display block

  .BuildingInfoWrap
    padding-bottom 5px
    margin-top -3px
    span
      display block
      font-size 0.75rem
      color $color_grey
      margin-bottom 2.5px

  .OptionList
    optionList()

  .AmountInputWrap
    margin-bottom 20px
    background-color $color_grey_lightestest
    border 1px solid lighten($color_grey_lighter, 25%)
    padding 10px
    .Title
      font-weight bold
      text-transform uppercase
      padding-bottom 5px
    .Row
      width 100%
      display flex
      align-items center
      margin-bottom 10px
      > *
        margin-right 4px
      span
        white-space nowrap
        &:last-child
          margin-right 0
      >>> .InputField
        flex-grow 2
        margin-bottom 0
    .Amount
      color $color_grey
      font-size 0.875rem
      text-transform uppercase
</style>
