/* eslint-disable no-use-before-define */
import { union, sortBy } from 'lodash-es'
import InterConnection from '@/globals/javascript/models/InterConnection'

export const screeningGroupedTypes = ({
  interConnections,
  screeningTypes,
  categoryID = '',
  unitID = '',
  overlayData = null,
}) => {
  // 1. Get grouped types
  const groupedTypes = getRawGroupedTypes({
    interConnections,
    screeningTypes,
    categoryID,
    unitID,
  })

  // 2. Enrich from overlayData
  const allowedGroupedTypes = []
  if (overlayData) {
    groupedTypes.forEach((groupedType) => {
      // 2.1 Set current group
      if (groupedType.typeIDs.includes(overlayData?.typeData?.id)) {
        groupedType.options.isCurrentGroup = true
      }

      // 2.2 Check to only show grouped types with matching units
      let isAnyAllowed = false
      groupedType.typeItems.forEach((typeItem) => {
        let matchFound = false
        if (overlayData.showInnerSelect) {
          matchFound = InterConnection.checkInterConnectionUnits(
            typeItem.type,
            overlayData.typeData,
          )
        }
        else {
          matchFound = InterConnection.checkInterConnectionUnits(
            overlayData.typeData,
            typeItem.type,
          )
        }

        if (matchFound) {
          isAnyAllowed = true
        }
        else {
          typeItem.cantBeUsed = true
        }
      })

      if (!isAnyAllowed) {
        return
      }

      allowedGroupedTypes.push(groupedType)
    })
  }

  // 3. Check to show amounts on types shown multiple times
  const foundTypeIDs = []
  groupedTypes.forEach((groupedType) => {
    groupedType.typeItems.forEach((typeItem) => {
      if (!foundTypeIDs.includes(typeItem.type.id)) {
        foundTypeIDs.push(typeItem.type.id)
        typeItem.isFirstInstance = true
      }
      else {
        typeItem.isFirstInstance = false
      }
    })
  })

  return overlayData ? allowedGroupedTypes : groupedTypes
}

const getCleanGroupData = () => {
  const data = {
    groupID: '',
    lastUpdated: 0,
    typeItems: [],
    categoryIDs: [],
    typeIDs: [],
    unitIDs: [],
    options: {
      isGroup: false,
      isOwnTypeAsWell: false,
      isAllByItSelf: false,
      isCurrentGroup: false,
      isAmountRequired: false,
    },
  }

  return JSON.parse(JSON.stringify(data))
}

export const getRawGroupedTypes = ({
  interConnections,
  screeningTypes,
  categoryID,
  unitID,
}) => {
  // 1. Make groupes
  // 1.1 From inter connections
  const usedInterConnectionGroupIDs = []
  let usedTypeIDs = []
  let groupedTypes = []
  interConnections.forEach((interConnection) => {
    if (usedInterConnectionGroupIDs.includes(interConnection.groupID)) {
      return
    }
    usedInterConnectionGroupIDs.push(interConnection.groupID)

    // Check that all types in interconnection is present
    const interConnectionsInGroup = interConnections.filter(
      (x) => x.groupID === interConnection.groupID,
    )
    const isAllTypesPresent = interConnectionsInGroup.every((interConnection) => {
      const innerType = screeningTypes.find((x) => x.id === interConnection.innerTypeID)
      const outerType = screeningTypes.find((x) => x.id === interConnection.outerTypeID)

      return !!(innerType && outerType)
    })

    if (!isAllTypesPresent) {
      return
    }

    const firstType = screeningTypes.find((x) => x.id === interConnection.innerTypeID)
    usedTypeIDs = union(usedTypeIDs, [firstType.id])

    const groupData = getCleanGroupData()
    groupData.groupID = interConnection.groupID
    groupData.lastUpdated = firstType.lastUpdated.seconds
    groupData.typeItems.push({
      type: firstType,
      innerInterConnection: null,
      outerInterConnection: null,
    })
    groupData.options.isGroup = true

    // Find all inner connections
    let nextInnerItem = interConnections.find(
      (x) => x.outerTypeID === firstType.id
        && x.groupID === groupData.groupID,
    )
    while (nextInnerItem) {
      groupData.typeItems[0].innerInterConnection = nextInnerItem
      // eslint-disable-next-line no-loop-func
      const type = screeningTypes.find((x) => x.id === nextInnerItem.innerTypeID)
      usedTypeIDs = union(usedTypeIDs, [type.id])
      groupData.typeItems.unshift({
        type,
        innerInterConnection: null,
        outerInterConnection: nextInnerItem,
      })
      if (type.lastUpdated.seconds > groupData.lastUpdated) {
        groupData.lastUpdated = type.lastUpdated.seconds
      }
      nextInnerItem = interConnections.find(
        // eslint-disable-next-line no-loop-func
        (x) => x.outerTypeID === nextInnerItem.innerTypeID
          && x.groupID === groupData.groupID,
      )
    }

    // Find all outer connections
    let nextOuterItem = interConnections.find(
      (x) => x.innerTypeID === firstType.id
        && x.groupID === groupData.groupID,
    )
    while (nextOuterItem) {
      const lastItem = groupData.typeItems[groupData.typeItems.length - 1]
      lastItem.outerInterConnection = nextOuterItem
      // eslint-disable-next-line no-loop-func
      const type = screeningTypes.find((x) => x.id === nextOuterItem.outerTypeID)
      usedTypeIDs = union(usedTypeIDs, [type.id])
      groupData.typeItems.push({
        type,
        innerInterConnection: nextOuterItem,
        outerInterConnection: null,
      })
      if (type.lastUpdated.seconds > groupData.lastUpdated) {
        groupData.lastUpdated = type.lastUpdated.seconds
      }
      nextOuterItem = interConnections.find(
        // eslint-disable-next-line no-loop-func
        (x) => x.innerTypeID === nextOuterItem.outerTypeID
          && x.groupID === groupData.groupID,
      )
    }

    groupedTypes.push(groupData)
  })

  // 1.2 From from types in groups that are also by themselves
  usedTypeIDs.forEach((typeID) => {
    const type = screeningTypes.find((x) => x.id === typeID)
    if (!type.isOwnTypeAsWell) {
      return
    }

    const groupData = getCleanGroupData()
    groupData.groupID = `single-${ type.id }`
    groupData.lastUpdated = type.lastUpdated.seconds
    groupData.typeItems.push({
      type,
      innerInterConnection: null,
      outerInterConnection: null,
    })
    groupData.options.isOwnTypeAsWell = true

    groupedTypes.push(groupData)
  })

  // 1.3 From single types
  screeningTypes.forEach((type) => {
    if (usedTypeIDs.includes(type.id)) {
      return
    }

    const groupData = getCleanGroupData()
    groupData.groupID = `single-${ type.id }`
    groupData.lastUpdated = type.lastUpdated.seconds
    groupData.typeItems.push({
      type,
      innerInterConnection: null,
      outerInterConnection: null,
    })
    groupData.options.isAllByItSelf = true

    groupedTypes.push(groupData)
  })

  // 2. Set missing IDs for each group
  groupedTypes.forEach((groupedType) => {
    groupedType.typeItems.forEach((typeItem) => {
      const { type } = typeItem
      groupedType.typeIDs = union(groupedType.typeIDs, [type.id])
      groupedType.categoryIDs = union(groupedType.categoryIDs, [type.categoryID])
      groupedType.unitIDs = union(groupedType.unitIDs, type.unitIDs)
    })
  })

  // 3. Filter by category
  if (categoryID) {
    groupedTypes = groupedTypes.filter((x) => x.categoryIDs.includes(categoryID))
  }

  // 4. Filter by unitID
  if (unitID) {
    groupedTypes = groupedTypes.filter((x) => x.unitIDs.includes(unitID))
  }

  // 5. Find groups that require amounts
  groupedTypes.forEach((groupedType) => {
    if (!groupedType.options.isGroup) {
      return
    }

    let isFound = false
    groupedType.typeItems.forEach((typeItem, index) => {
      const { outerInterConnection } = typeItem

      if (isFound || !outerInterConnection) {
        return
      }

      // 5.1 Check for stuck split status
      if (outerInterConnection.splitStatus === 'stuck') {
        isFound = true
        return
      }

      // 5.2 Check if 2 types could contaminate each other
      if (outerInterConnection.effectDirection === 'none') {
        return
      }

      const innerType = typeItem.type
      const outerType = groupedType.typeItems[index + 1].type

      // 5.2.1 Check left
      if (['both', 'left'].includes(outerInterConnection.effectDirection)) {
        if (
          outerType.samples.sampleIDs.length
          || outerType.samples?.manualSampleIDs?.length
          || outerType.assessments?.sampleIDs?.length
        ) {
          isFound = true
          return
        }
      }

      // 5.2.2 Check right
      if (['both', 'right'].includes(outerInterConnection.effectDirection)) {
        // Check for coating
        if (innerType.coating.coatingTypeIDs.length) {
          isFound = true
          return
        }
        // Check material
        if (
          innerType.samples.sampleIDs.length
          || innerType.samples?.manualSampleIDs?.length
          || innerType.assessments?.sampleIDs?.length
        ) {
          isFound = true
          return
        }
      }
    })

    groupedType.options.isAmountRequired = isFound
  })

  return sortBy(groupedTypes, 'lastUpdated')
}
